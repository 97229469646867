import React, { useState } from "react";
import { Button } from "reactstrap";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import PrimaryToast from "../Toast/ToastMessage";
import { updateProductOptions } from "services/ProductsServices";

function UpdateProductOptionsPopup({
  handleClick,
  isOpen,
  handleFileUpload,
  loading,
  setLoading,
}) {
  const [fields, setFields] = useState({});
  const [selection, setSelection] = useState("");
  const [file, setFile] = useState(null);

  const handleChange = e => {
    const { name, value } = e.target;
    setFields(prev => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setFields({});
    setSelection("");
    setFile(null);
    handleClick();
  };

  const handleSave = async () => {
    if (!file) {
      PrimaryToast({
        type: "error",
        message: "Please select a file to upload.",
      });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        is_admin: true,
        file,
      };

      if (selection === "seller") {
        payload.seller_id = Number(fields.seller_id) || "";
      } else {
        const productIdsString = fields.product_ids
          ?.split(",")
          .map(id => Number(id.trim()))
          .filter(id => !isNaN(id));

        if (!productIdsString) {
          throw new Error("Invalid Product IDs. Please enter valid numbers.");
        }

        payload.product_ids = productIdsString;
      }

      const response = await updateProductOptions(payload);

      if (response?.status >= 200 && response?.status < 300) {
        PrimaryToast({
          type: "success",
          message: response.data?.message || "File uploaded successfully!",
        });
        handleClose();
      } else {
        throw new Error(
          response?.data?.message || "Upload failed. Please try again."
        );
      }
    } catch (error) {
      PrimaryToast({
        type: "error",
        message:
          error?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryPopup
      title="Update Product Options"
      handleClick={handleClick}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
      isOpen={isOpen}
    >
      <div className="mb-3">
        <label className="form-label">Select ID Type</label>
        <div className="d-flex gap-3">
          {["seller", "product"].map(type => (
            <div className="form-check" key={type}>
              <input
                className={`form-check-input ${type}`}
                type="radio"
                name="selection"
                value={type}
                checked={selection === type}
                onClick={() => setSelection(type)}
                id={`selected-${type}`}
              />
              <label htmlFor={`selected-${type}`} className="form-check-label">
                {type === "seller" ? "Seller ID" : "Product IDs"}
              </label>
            </div>
          ))}
        </div>
      </div>

      {selection && (
        <div className="mb-3">
          <PrimaryInput
            name={selection === "seller" ? "seller_id" : "product_ids"}
            handleChange={handleChange}
            label={selection === "seller" ? "Seller ID" : "Product IDs"}
            placeholder={
              selection === "seller"
                ? "Enter Seller ID"
                : "Enter Product IDs (e.g., 21, 45, 67)"
            }
            value={
              fields[selection === "seller" ? "seller_id" : "product_ids"] || ""
            }
          />
        </div>
      )}

      <div className="mb-3">
        <label className="form-label">Upload File</label>
        <input
          type="file"
          className="form-control"
          onChange={e => {
            handleFileUpload(e);
            setFile(e.target.files[0]); // Update local state
          }}
        />
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          className="ms-2"
          disabled={loading || !file}
          onClick={handleSave}
        >
          Upload
        </Button>
      </div>
    </PrimaryPopup>
  );
}

export default UpdateProductOptionsPopup;
