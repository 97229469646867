import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getJobs = (status, page, search, start_date, end_date) => {
  return getApi({
    url: `admin/jobs`,
    data: {
      is_admin: true,
      status: status || undefined,
      page,
      search:search || undefined,
      start_date: start_date || undefined,
      end_date: end_date || undefined
    },
  }).then(res => res.data);
};

export const retryJobs = queue_ids => {
  return axiosInstance({
    method: "POST",
    url: `${siteApiUrl}admin/jobs/retry`,
    withCredentials: true,
    data: {
      queue_ids,
      is_admin: true,
    },
    headers: getHttpHeaders(),
  });
};
