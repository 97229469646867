import {
  ORDERS_HISTORIES,
  GET_SHIPPING_ADDRESS,
  GET_TRACKING_NUMBER,
  ADD_HISTORY,
} from "store/actions/orders";

const initialState = {
  orderHistories: [],
  tracking_numbers: [],
  shippingAddress: {},
};

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: payload,
      };

    case ORDERS_HISTORIES:
      return {
        ...state,
        orderHistories: payload,
      };
      break;
    case ADD_HISTORY:
      return {
        ...state,
        orderHistories: [payload, ...state.orderHistories],
      };
      break;
    case GET_TRACKING_NUMBER:
      return {
        ...state,
        tracking_numbers: payload !== "" ? [payload] : [],
      };
    default:
      return state;
  }
};

export default ordersReducer;
