import React, { useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import TopHeading from "../../components/Common/TopHeading";
import PrimaryTable from "../../components/Common/PrimaryTable";
import ProgressPopup from "../../components/Common/Popups/ProgressPopup";
import PrimaryPagination from "../../components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "../../components/Common/Toast/ToastMessage";
import { setPageTitle } from "../../utils/pageTitle";
import PrimarySearch from "../../components/Common/PrimarySearch";
import PrimaryPopup from "../../components/Common/Popups/PrimaryPopup";
import {
  getAllPODLibrary,
  createPODAsset,
  updatePODAsset,
  deletePODAsset,
} from "../../services/PODLibraryService";
import EditIcon from "../../assets/images/edit-icon-white.svg";
import DeleteIcon from "../../assets/images/delete-icon-white.svg";
import DownloadIcon from "../../assets/images/download-icon-white.png";

const PODLibrary = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState(null);
  const [assetName, setAssetName] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setPageTitle("POD Library");
  }, []);

  useEffect(() => {
    const page = Number(queryParams.get("page")) || 1;
    const searchVal = queryParams.get("search") || " ";
    setSearch(searchVal);
    const params = {
      page: page,
      search: searchVal.trim() || " ",
    };

    fetchData(params);
  }, [queryParams.toString()]);

  const fetchData = async params => {
    setLoading(true);
    try {
      const response = await getAllPODLibrary(params);
      setAssets(response?.data?.data || []);
      setPageCount(response?.data?.last_page || 1);
    } catch (error) {
      PrimaryToast({ type: "error", message: "Failed to fetch assets" });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (search.trim()) {
      console.log(search);
      queryParams.set("search", search);
    } else {
      queryParams.delete("search");
    }
    queryParams.delete("page");
    navigate(`?${queryParams.toString()}`);
  };

  const handleEnterKeyPress = e => {
    if (e.key == "Enter") {
      handleSearch();
    }
  };
  const handleOnSelect = (row, isSelect) => {
    setSelectedRowKeys(prev =>
      isSelect ? [...prev, row.id] : prev.filter(id => id !== row.id)
    );
  };

  const handleOnSelectAll = (isSelect, rows) => {
    setSelectedRowKeys(isSelect ? rows.map(row => row.id) : []);
  };

  const selectRow = useMemo(
    () => ({
      mode: "checkbox",
      nonSelectableClasses: "hide-checkbox",
      onSelect: handleOnSelect,
      onSelectAll: handleOnSelectAll,
      selected: selectedRowKeys,
    }),
    [handleOnSelect, handleOnSelectAll, selectedRowKeys]
  );

  const handleDelete = async selectedIds => {
    const ids = Array.isArray(selectedIds) ? selectedIds : [selectedIds];
    try {
      setLoading(true);
      await deletePODAsset(ids);
      setSelectedRowKeys(prev => prev.filter(id => !ids.includes(id)));
      await fetchData();

      if (assets.length === ids.length && pageNo > 1) {
        queryParams.set("page", pageNo - 1);
        navigate(`?${queryParams.toString()}`);
      }

      PrimaryToast({
        type: "success",
        message: "Items deleted successfully",
      });
    } catch (error) {
      PrimaryToast({ type: "error", message: "Delete operation failed" });
    } finally {
      setLoading(false);
    }
  };

  const baseColumns = useMemo(
    () => [
      { dataField: "name", text: "Asset Name", sort: true },
      {
        dataField: "preview",
        text: "Preview",
        formatter: cell => (
          <img
            src={cell}
            alt="Preview"
            className="img-thumbnail"
            style={{ height: "100px" }}
          />
        ),
      },
      {
        dataField: "created_at",
        text: "Upload Date",
        formatter: cell => moment(cell).format("DD MMM YYYY"),
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: (_, row) => (
          <div className="d-flex gap-2">
            <div
              className="action-btn bg-secondary me-2 cursor-pointer"
              onClick={() => {
                window.open(row.file, "_blank");
              }}
            >
              <img height={25} width={25} src={DownloadIcon} alt="Download" />
            </div>
            <div
              className="action-btn edit-icon-bg bold  me-2 cursor-pointer"
              onClick={() => handleEditClick(row)}
            >
              <img height={20} width={20} src={EditIcon} alt="Edit" />
            </div>

            <div
              className="action-btn delete-btn-bg"
              onClick={() => handleDelete([row.id])}
            >
              <img height={22} width={22} src={DeleteIcon} alt="Delete" />
            </div>
          </div>
        ),
      },
    ],
    [handleDelete]
  );

  const handleEditClick = asset => {
    setEditData(asset);
    setAssetName(asset.name);
    setPreviewImage(asset.preview);
    setIsOpen(true);
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!assetName.trim()) newErrors.assetName = "Name is required";
    if (!previewFile && !editData)
      newErrors.previewImage = "Preview image is required";
    if (!file && !editData) newErrors.file = "Design file is required";
    return newErrors;
  }, [assetName, previewFile, editData, file]);

  const handleSubmit = useCallback(async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) return setErrors(formErrors);

    try {
      if (editData) {
        await updatePODAsset(editData.id, assetName, previewFile, file);
        PrimaryToast({
          type: "success",
          message: "Asset updated successfully",
        });
      } else {
        await createPODAsset(assetName, previewFile, file);
        PrimaryToast({
          type: "success",
          message: "Asset created successfully",
        });
      }

      setAssetName("");
      setPreviewImage(null);
      setFile(null);
      setErrors({});
      setEditData(null);
      setIsOpen(false);
      await fetchData();
    } catch (error) {
      PrimaryToast({
        type: "error",
        message: error.response?.data?.message || "Operation failed",
      });
    }
  }, [editData, assetName, previewFile, file]);

  return (
    <div className="page-content">
      {loading && <ProgressPopup label="Loading..." />}

      <div className="d-flex align-items-center justify-content-between mb-3">
        <TopHeading heading="POD Library" />
      </div>

      <Card>
        <CardBody>
          <div className="d-flex gap-2 align-items-center mb-4">
            <PrimarySearch
              placeholder="Search assets..."
              value={search || ""}
              handleChange={e => setSearch(e.target.value)}
              handleKeyPress={handleEnterKeyPress}
            />
            <Button
              color="primary"
              onClick={handleSearch}
              disabled={search.trim() === "" && !queryParams.get("search")}
            >
              Search
            </Button>

            {selectedRowKeys.length > 0 && (
              <Button
                color="danger"
                onClick={() => handleDelete(selectedRowKeys)}
                disabled={loading}
              >
                Delete Selected ({selectedRowKeys.length})
              </Button>
            )}

            <Button
              color="primary"
              className="ms-auto"
              onClick={() => setIsOpen(true)}
            >
              + Add New Asset
            </Button>
          </div>

          <PrimaryTable
            columns={baseColumns}
            data={assets}
            keyField="id"
            selectRow={selectRow}
            headerClasses="bg-light"
            rowClasses="py-3"
          />

          {pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>

      <PrimaryPopup
        isOpen={isOpen}
        handleClick={() => setIsOpen(!isOpen)}
        title={`${editData ? "Edit" : "Add New"} Asset`}
      >
        <Form className="p-3">
          <FormGroup className="mb-4">
            <Label>Asset Name</Label>
            <Input
              type="text"
              value={assetName}
              onChange={e => setAssetName(e.target.value)}
              invalid={!!errors.assetName}
            />
            {errors.assetName && (
              <div className="text-danger small">{errors.assetName}</div>
            )}
          </FormGroup>

          <FormGroup className="mb-4">
            <Label>Preview Image</Label>
            <Input
              type="file"
              accept="image/*"
              onChange={e => {
                const file = e.target.files[0];
                if (file) {
                  setPreviewFile(file);
                  setPreviewImage(URL.createObjectURL(file));
                }
              }}
              invalid={!!errors.previewImage}
            />
            {errors.previewImage && (
              <div className="text-danger small">{errors.previewImage}</div>
            )}
          </FormGroup>

          <FormGroup className="mb-4">
            <Label>Design File</Label>
            <Input
              type="file"
              onChange={e => setFile(e.target.files[0])}
              invalid={!!errors.file}
            />
            {errors.file && (
              <div className="text-danger small">{errors.file}</div>
            )}
          </FormGroup>

          <div className="d-flex justify-content-end gap-3">
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSubmit}>
              {editData ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </PrimaryPopup>
    </div>
  );
};

export default PODLibrary;
