export const ORDERS_HISTORIES = "ORDERS_HISTORIES";
export const ADD_HISTORY = "ADD_HISTORY";
export const GET_TRACKING_NUMBER = "GET_TRACKING_NUMBER";
export const GET_SHIPPING_ADDRESS = "GET_SHIPPING_ADDRESS";

export const loadHistory = (payload) => {
    return {
        type:ORDERS_HISTORIES,
        payload
    }
};

export const getShippingAddress  = (payload) => {
    return {
        type:GET_SHIPPING_ADDRESS,
        payload,
    }
}

export const addInHistory = (payload) => {

    return {
        type:ADD_HISTORY,
        payload
    }
};

export const getTrackingNumber = (payload) => {
    return {
        type:GET_TRACKING_NUMBER,
        payload
    }
}