import React from "react";
import { Navigate } from "react-router-dom";

// Dispute

import BraintreeDispute from "pages/Dispute/BraintreeDispute";
import DisputeManagement from "pages/Dispute/DisputeManagement";
import ResponseDispute from "pages/Dispute/ResponseDispute";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// orders

import OrderManagement from "pages/Order-Management/Index";
import OrderDetail from "pages/Order-Management/OrderDetail";
import OrderRefunds from "pages/Order-Management/OrderRefunds";

// notifications

import OrdersNotifications from "pages/Notifications/OrderNotification";
import SellersNotifications from "pages/Notifications/SellerNotification";

// seller

import SellerStores from "pages/Seller/SellerStores";
import HyperWalletInfo from "pages/Seller/SellerStores/HyperWalletInfo";
import PayoutMethodsApproval from "pages/Seller/PayoutMethodApproval";
import EditStakeHolders from "pages/Seller/SellerStores/StakeHolders/EditStakeholder";
import CreateStakeHolders from "pages/Seller/SellerStores/StakeHolders/CreateStakeholder";
import AnnouncementsComponent from "pages/Seller/Announcements";
import OrderPaymentApproval from "pages/Seller/OrderPaymentApprovals";
import WithdrawRequests from "pages/Seller/WithdrawRequests";
import EditStore from "pages/Seller/SellerStores/Edit";
import EditPayoutMethod from "pages/Seller/SellerStores/EditPayout";
import SellerLogs from "pages/Seller/SellerStores/Logs";
import SellerComments from "pages/Seller/SellerStores/Comments";
import SinglePayoutApproval from "pages/Seller/PayoutMethodApproval/SinglePayoutApproval";
import CreateAnnouncement from "pages/Seller/Announcements/CreateAnnouncement";
import EditAnnouncement from "pages/Seller/Announcements/EditAnnouncement";
import SellerGroup from "pages/Seller/SellerGroup";
import CreateSellerGroup from "pages/Seller/SellerGroup/CreateGroup";
import EditSellerGroup from "pages/Seller/SellerGroup/EditGroup";
import SellersList from "pages/Seller/SellerGroup/SellersList";
import SellerPaymentMethods from "pages/Seller/SellerPaymentMethods";
import CreatePaymentMethod from "pages/Seller/SellerPaymentMethods/CreatePaymentMethod";
import EditPaymentMethod from "pages/Seller/SellerPaymentMethods/EditPaymentMethod";
import SingleWithDrawRequest from "pages/Seller/WithdrawRequests/SingleWithDrawRequest";
import SellerRestriction from "pages/Seller/SellerRestriction";

// Products

import AllProducts from "pages/Products";
import ProductsApproval from "pages/Products/ProductsApproval";
import ProductGroups from "pages/Products/ProductGroups";
import ProductCategories from "pages/Products/ProductCategories";
import ProductCollection from "pages/Products/Collections/ProductCollection";
import CreateGroup from "pages/Products/ProductGroups/CreateGroup";
import EditGroup from "pages/Products/ProductGroups/EditGroup";
import CreateCatrgory from "pages/Products/ProductCategories/CreateCatrgory";
import EditCategory from "pages/Products/ProductCategories/EditCategory";
import EditCollection from "pages/Products/Collections/EditCollection";
import ProductHistory from "pages/Products/ProductDetail";

// SEO Keywords

import { SeoKeywords } from "pages/SEO/keywords";
import RelatedGroups from "pages/SEO/RelatedGroups";
import CreateRelatedGroup from "pages/SEO/RelatedGroups/CreateRelatedGroup";
import SeoCollections from "pages/SEO/Collections";
import { EditRelatedGroup } from "pages/SEO/RelatedGroups/EditRelatedGroup";

//Customers

import CustomersList from "../pages/Customers";
import EditCustomer from "pages/Customers/EditCustomer";
import CustomerDetail from "pages/Customers/CustomerDetail";

// Employee

import EmployeeListing from "pages/Employees";
import CreateEmployee from "pages/Employees/CreateEmployee";
import EditEmployee from "pages/Employees/EditEmployee";
import EmployeeDetail from "pages/Employees/EmployeeDetail";
import TaskList from "pages/Employees/Tasks/List";
import MyTasks from "pages/Employees/MyTask";
import TaskDetails from "pages/Employees/MyTask/TaskDetails";

// Settings

import Roles from "pages/Settings/Roles";
import CreateRole from "pages/Settings/Roles/CreateRole";
import EditRole from "pages/Settings/Roles/EditRole";
import RoleDetails from "pages/Settings/Roles/RoleDetails";

// Marketing
import Promotions from "pages/Marketing/Promotions";
import CreatePromotion from "pages/Marketing/Promotions/Create";
import EditPromotion from "pages/Marketing/Promotions/Edit";
import MarketplaceFeed from "pages/Marketing/MarketplaceFeed";
import EditMarketplaceFeed from "pages/Marketing/MarketplaceFeed/Edit";
import CreateMarketplaceFeed from "pages/Marketing/MarketplaceFeed/Create";
import AdsPlatforms from "pages/Marketing/Ads/Platforms";
import AddPlatform from "pages/Marketing/Ads/Platforms/AddPlatform";
import EditPlatform from "pages/Marketing/Ads/Platforms/EditPlatform";
import AdsPlacement from "pages/Marketing/Ads/Placements";
import AddPlacement from "pages/Marketing/Ads/Placements/AddPlacement";
import EditPlacement from "pages/Marketing/Ads/Placements/EditPlacement";
import AdsCenter from "pages/Marketing/Ads/Centers";
import ProductsAd from "pages/Marketing/Ads/ProductsAd/index";
import AdsDetails from "pages/Marketing/Ads/ProductsAd/AdsDetails";
import CampaignsListing from "pages/Marketing/Campaigns";

// Gift Cards

import GiftCardApproval from "pages/GiftCards/GiftCardApproval";
import GiftCardsListing from "pages/GiftCards";
import CreateGiftCards from "pages/GiftCards/CreateGiftCards";
import EditGiftCard from "pages/GiftCards/EditGiftCard";

// Coupons

import CouponsList from "pages/Coupons";
import CreateCoupon from "pages/Coupons/CreateCoupon";
import EditCoupon from "pages/Coupons/EditCoupon";

// Reports

import SalesByChannel from "pages/Reports/SalesByChannel";
import SalesBySeller from "pages/Reports/SalesBySeller";
import OverTime from "pages/Reports/OverTime";
import ProductPurchases from "pages/Reports/ProductPurchases";
import ProductStore from "pages/Reports/ProductStore";

// Others

import ScheduleCommands from "pages/Others/ScheduleCommands";
import CreateCommand from "pages/Others/ScheduleCommands/CreateCommand";
import EditCommand from "pages/Others/ScheduleCommands/EditCommand";
import Complaints from "pages/Others/Complaints/ComplaintList";
import BugsReport from "pages/Others/BugsReport";
import DisputeReview from "pages/Others/DisputeReview";
import Redirects from "pages/Others/Redirects";
import RespondComplaint from "pages/Others/Complaints/RespondComplaint";
import CreateRedirect from "pages/Others/Redirects/CreateRedirect";
import EditRedirect from "pages/Others/Redirects/EditRedirect";
import ResponseLogs from "pages/Others/ResponseLogs";
import ComplaintDetails from "pages/Others/Complaints/ComplaintDetails";
import DMCAEmailsList from "pages/Others/DMCA";
import DMCASellerApproval from "pages/Others/DMCAApproval/DMCASellerApproval";
import PODLibrary from "pages/Others/PODLibrary";

// Theme

import WigetsListing from "pages/Theme/Widgets";
import CreateWidget from "pages/Theme/Widgets/CreateWidget";
import EditWidget from "pages/Theme/Widgets/EditWidget";
import MobileWelcomeListing from "pages/Theme/MobileApp";
import CreateWelcome from "pages/Theme/MobileApp/Create";
import EditWelcome from "pages/Theme/MobileApp/Edit";

// Flags

import FlagedKeyword from "pages/Flags/FlagedKeyword";
import CreateKeyword from "pages/Flags/FlagedKeyword/CreateKeyword";
import EditKeyword from "pages/Flags/FlagedKeyword/EditKeyword";
import AdultKeywords from "pages/Flags/AdultKeywords";
import CreateAdultKeywords from "pages/Flags/AdultKeywords/Create";
import EditAdultKeywords from "pages/Flags/AdultKeywords/Edit";
import CreateTask from "pages/Employees/Tasks/Create";
import EditTask from "pages/Employees/Tasks/Edit";
import EditCenter from "pages/Marketing/Ads/Centers/EditCenter";
import TwoFactorAuthentication from "pages/2FA";
import ResetPasswordPage from "pages/Authentication/ResetPassword";
import TwoFaAtuh from "pages/Authentication/2FaAtuh";

// Cache

import Cache from "pages/Cache";
import Analytics from "pages/Reports/Analytics";
import SalesByGroup from "pages/Reports/SalesByGroup";
import UnfulfilledSellerOrders from "pages/Reports/UnfulfilledSellerOrders";
import Refunds from "pages/Reports/Refunds";
import EmailDetail from "pages/Others/DMCA/EmailDetail";
import CreateCampaigns from "pages/Marketing/Campaigns/Create";
import EditCampaigns from "pages/Marketing/Campaigns/Edit";
import CreateCollection from "pages/Products/Collections/CreateCollection";
import ProductPurchasesDetail from "pages/Reports/ProductPurchasesDetail";
import ProductSalesBySeller from "pages/Reports/ProductSalesBySeller";
import ReferralProductsDetail from "pages/Reports/ReferralProductsDetail";
import { SellersKYC } from "pages/Seller/Kyc/AllSellersKYC";
import SingleKycRecord from "pages/Seller/Kyc/SingleKycRecord";
import SalesByCategory from "pages/Reports/SalesByCategory";
import SalesByGroupName from "pages/Reports/SalesByGroupName";
import Jobs from "pages/Others/Jobs";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/two-factor-authentication", component: TwoFactorAuthentication },

  // seller

  { path: "/seller/stores", component: SellerStores },
  { path: "/seller/stores/stakeholders/create", component: CreateStakeHolders },
  { path: "/seller/stores/stakeholders/edit", component: EditStakeHolders },
  { path: "/seller/payout-approval", component: PayoutMethodsApproval },
  { path: "/seller/announcements", component: AnnouncementsComponent },
  { path: "/seller/order-payout-approval", component: OrderPaymentApproval },
  { path: "/seller/withdraw-requests", component: WithdrawRequests },
  { path: "/seller/payout-methods", component: SellerPaymentMethods },

  { path: "/seller/stores/edit/:id", component: EditStore },
  { path: "/seller/stores/edit-payout/:id", component: EditPayoutMethod },
  { path: "/seller/stores/logs/:id", component: SellerLogs },
  { path: "/seller/stores/comments/:id", component: SellerComments },
  {
    path: "/seller/payout-approval/:id",
    component: SinglePayoutApproval,
  },
  { path: "/seller/announcements/create", component: CreateAnnouncement },
  { path: "/seller/announcements/edit/:id", component: EditAnnouncement },
  { path: "/seller/groups", component: SellerGroup },
  { path: "/seller/groups/create", component: CreateSellerGroup },
  { path: "/seller/groups/edit/:id", component: EditSellerGroup },
  { path: "/seller/groups/:id/sellers-list", component: SellersList },
  { path: "/seller/payout-methods/create", component: CreatePaymentMethod },
  { path: "/seller/payout-methods/edit/:id", component: EditPaymentMethod },
  { path: "/seller/withdraw-requests/:id", component: SingleWithDrawRequest },
  { path: "/seller/kyc-management", component: SellersKYC },
  { path: "/seller/kyc-management/:id", component: SingleKycRecord },
  { path: "/seller/restriction", component: SellerRestriction },
  {
    path: "/seller/stores/:id/hyperwallet-info",
    component: HyperWalletInfo,
  },

  // products

  { path: "/products/list", component: AllProducts },
  { path: "/products/details/:id", component: ProductHistory },
  { path: "/products/approval", component: ProductsApproval },
  { path: "/products/groups", component: ProductGroups },
  { path: "/products/categories", component: ProductCategories },
  { path: "/products/collections", component: ProductCollection },
  { path: "/products/collections/create", component: CreateCollection },
  { path: "/products/collections/edit/:id", component: EditCollection },
  { path: "/products/groups/create", component: CreateGroup },
  { path: "/products/groups/edit/:id", component: EditGroup },
  { path: "/products/categories/create", component: CreateCatrgory },
  { path: "/products/categories/edit/:id", component: EditCategory },
  { path: "/marketing/product-ads", component: ProductsAd },
  { path: "/marketing/product-ads/details/:id", component: AdsDetails },

  // SEO
  { path: "/seo-keywords/list", component: SeoKeywords },
  { path: "/seo-keywords/collections", component: SeoCollections },
  { path: "/seo-keywords/related-groups", component: RelatedGroups },
  {
    path: "/seo-keywords/related-groups/create",
    component: CreateRelatedGroup,
  },
  {
    path: "/seo-keywords/related-groups/edit/:id",
    component: EditRelatedGroup,
  },
  // Dispute
  { path: "disputes/braintree", component: BraintreeDispute },
  { path: "disputes/managments", component: DisputeManagement },
  { path: "disputes/managments/response/:id", component: ResponseDispute },

  // Customers,
  { path: "/customers/list", component: CustomersList },
  { path: "/customers/edit/:id", component: EditCustomer },
  { path: "/customers/detail/:id", component: CustomerDetail },

  // Employee,

  { path: "/employees/list", component: EmployeeListing },
  { path: "/employees/create", component: CreateEmployee },
  { path: "/employees/edit/:id", component: EditEmployee },
  { path: "/user/detail/:id", component: EmployeeDetail },
  { path: "/employees/tasks", component: TaskList },
  { path: "/employees/tasks/edit/:id", component: EditTask },
  { path: "/employees/my-task", component: MyTasks },
  { path: "/employees/my-task/:id", component: TaskDetails },
  { path: "/employees/my-task/create", component: CreateTask },
  { path: "/employees/my-task/edit/:id", component: EditTask },

  // Settings

  { path: "/settings/roles", component: Roles },
  { path: "/settings/roles/create", component: CreateRole },
  { path: "/settings/roles/:id", component: RoleDetails },
  { path: "/settings/roles/edit/:id", component: EditRole },

  // Marketing

  { path: "/marketing/promotions", component: Promotions },
  { path: "/marketing/promotions/create", component: CreatePromotion },
  { path: "/marketing/promotions/edit/:id", component: EditPromotion },
  { path: "/marketing/marketplace-feed", component: MarketplaceFeed },
  {
    path: "/marketing/marketplace-feed/edit/:id",
    component: EditMarketplaceFeed,
  },
  {
    path: "/marketing/marketplace-feed/create",
    component: CreateMarketplaceFeed,
  },
  { path: "/marketing/ads/platform", component: AdsPlatforms },
  { path: "/marketing/ads/platform/add", component: AddPlatform },
  { path: "/marketing/ads/platform/edit/:id", component: EditPlatform },
  { path: "marketing/ads/placements", component: AdsPlacement },
  { path: "marketing/ads/placements/add", component: AddPlacement },
  { path: "/marketing/ads/placements/edit/:id", component: EditPlacement },
  { path: "/marketing/ads/centers", component: AdsCenter },
  { path: "/marketing/ads/centers/edit/:id", component: EditCenter },
  { path: "/marketing/campaigns", component: CampaignsListing },
  { path: "/marketing/campaigns/create", component: CreateCampaigns },
  { path: "/marketing/campaigns/edit/:id", component: EditCampaigns },

  // Gift Cards
  { path: "/gift-cards/list", component: GiftCardsListing },
  { path: "/gift-cards/approvals", component: GiftCardApproval },
  { path: "/gift-cards/create", component: CreateGiftCards },
  { path: "/gift-cards/edit/:id", component: EditGiftCard },

  // Coupons

  { path: "/coupons/list", component: CouponsList },
  { path: "/coupons/create", component: CreateCoupon },
  { path: "/coupons/edit/:id", component: EditCoupon },

  // Report

  { path: "/reports/over-time", component: OverTime },
  { path: "/reports/sales-by-seller", component: SalesBySeller },
  { path: "/reports/sales-by-seller/:sellerId", component: ProductSalesBySeller },
  { path: "/reports/sales-by-channel", component: SalesByChannel },
  { path: "/reports/product-views-purchases", component: ProductPurchases },
  { path: "/reports/product-views-store", component: ProductStore },

  { path: "/reports/product-views-purchases/:productId", component: ProductPurchasesDetail },
  { path: "/reports/analytics", component: Analytics },
  { path: "/reports/sales-by-group", component: SalesByGroup },
  { path: "/reports/unfulfilled-seller-orders", component: UnfulfilledSellerOrders },
  { path: "/reports/sales-by-categories", component: SalesByCategory },
  { path: "/reports/analytics/:referral", component: ReferralProductsDetail },
  { path: "/reports/refunds", component: Refunds },
  {path:"/reports/sales-by-group-name", component:SalesByGroupName},

  // Others

  { path: "/others/schedule-command", component: ScheduleCommands },
  { path: "/others/schedule-command/create", component: CreateCommand },
  { path: "/others/schedule-command/edit/:id", component: EditCommand },
  { path: "/others/complaints", component: Complaints },
  { path: "/others/complaints/respond/:id", component: RespondComplaint },
  { path: "/others/complaints/details/:id", component: ComplaintDetails },
  { path: "/others/bugs-report", component: BugsReport },
  { path: "/others/dispute-review", component: DisputeReview },
  { path: "/others/jobs", component: Jobs },

  { path: "/others/redirects", component: Redirects },
  { path: "/others/redirects/create", component: CreateRedirect },
  { path: "/others/redirects/edit/:id", component: EditRedirect },
  { path: "/others/response-logs", component: ResponseLogs },
  { path: "/others/dmca-emails", component: DMCAEmailsList },
  { path: "/others/dmca-emails/:id", component: EmailDetail },
  { path: "/others/dmca-seller-approval", component: DMCASellerApproval },
  { path: "/others/pod-library", component:PODLibrary},

  // Theme

  { path: "/theme/widgets", component: WigetsListing },
  { path: "/theme/widgets/create", component: CreateWidget },
  { path: "/theme/widgets/edit/:id", component: EditWidget },
  { path: "/theme/mobile-app", component: MobileWelcomeListing },
  { path: "/theme/mobile-app/create", component: CreateWelcome },
  { path: "/theme/mobile-app/edit/:id", component: EditWelcome },

  // Flags

  { path: "/flags/keywords", component: FlagedKeyword },
  { path: "/flags/keywords/create", component: CreateKeyword },
  { path: "/flags/keywords/edit/:id", component: EditKeyword },
  { path: "/flags/adult-keywords", component: AdultKeywords },
  { path: "/flags/adult-keywords/create", component: CreateAdultKeywords },
  { path: "/flags/adult-keywords/edit/:id", component: EditAdultKeywords },

  // orders

  { path: "/orders/list", component: OrderManagement },
  { path: "/orders/details/:orderNumber", component: OrderDetail },
  { path: "/orders/refund-approvals", component: OrderRefunds },

  // notifications

  { path: "/notifications/orders", component: OrdersNotifications },
  { path: "/notifications/sellers", component: SellersNotifications },

  // cache

  { path: "/cache", component: Cache },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  { path: "/2fa-auth", component: TwoFaAtuh },

  { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/admin/reset-pass", component: ResetPasswordPage },
];

export { authProtectedRoutes, publicRoutes };