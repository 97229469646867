import React, { useState } from "react";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import { Button } from "reactstrap";
import ProgressPopup from "./ProgressPopup";
import PrimaryToast from "../Toast/ToastMessage";
import { allProductsCsv } from "services/ProductsServices";
import { downloadFile } from "../../../utils/downloadFile";

const DownloadAllProductsCsv = ({ isOpen, handleClick }) => {
  const [sellerId, setSellerId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = e => {
    const value = e.target.value;
    setSellerId(value);
  };

  const handleDownloadCsv = async () => {
    setLoading(true);
    try {
      const response = await allProductsCsv(sellerId);
      if (!response || !response.data)
        throw new Error("Invalid response from server.");

      downloadFile(response.data, response.headers);

      PrimaryToast({
        type: "success",
        message: "CSV downloaded successfully",
      });

      handleToggle();
    } catch (error) {
      console.error("CSV Download Error:", error);
      PrimaryToast({
        type: "error",
        message: error?.response?.data?.message || "Failed to download CSV.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    setSellerId("");
    handleClick();
  };

  return (
    <PrimaryPopup
      isOpen={isOpen}
      title="Products CSV"
      handleClick={handleToggle}
    >
      {loading ? (
        <ProgressPopup label="Generating CSV..." />
      ) : (
        <div>
          <PrimaryInput
            label="Seller ID"
            name="seller_id"
            placeholder="Enter Seller ID"
            value={sellerId}
            handleChange={handleInputChange}
          />

          <div
            className="d-flex justify-content-end mt-3"
            style={{ gap: "1rem" }}
          >
            <Button onClick={handleToggle} color="secondary">
              Cancel
            </Button>
            <Button color="primary" onClick={handleDownloadCsv}>
              Download CSV
            </Button>
          </div>
        </div>
      )}
    </PrimaryPopup>
  );
};

export default DownloadAllProductsCsv;
