import React, { useState } from "react";
import { Button } from "reactstrap";

import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import PrimaryToast from "../Toast/ToastMessage";
import { duplicateSellerProducts } from "services/ProductsServices";

function ProductDuplicatePopup({ handleClick, isOpen, loading, setLoading }) {
  const [fields, setFields] = useState({});

  const [selectInstant, setSelectInstant] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "product_ids" && selectInstant && /,/.test(value)) {
      PrimaryToast({
        type: "error",
        message: "Only one product ID is allowed in 'Instant' mode.",
      });
      return;
    }
    setFields(prev => ({
      ...prev,
      [name]: value,
      sync_type: selectInstant ? "instant" : "command",
    }));
    console.log(selectInstant);
  };
  false;
  const handleClose = () => {
    setFields({});
    handleClick();
  };

  const handleSave = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await duplicateSellerProducts(fields);
      if (response.status === 200) {
        PrimaryToast({ type: "success", message: response.data?.data });
        setFields(prev => ({
          ...prev,
          product_ids: "",
        }));
      }
    } catch (error) {
      console.log(error);
      PrimaryToast({ type: "error", message: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryPopup
      title={"Product Duplicate"}
      handleClick={handleClick}
      isScrollable={false}
      className={"d-flex justify-content-center align-items-center h-100 w-100"}
      isOpen={isOpen}
    >
      <div className="mb-3">
        <PrimaryInput
          name="seller_id"
          handleChange={handleChange}
          label="Seller ID"
          placeholder="Enter seller id"
          value={fields?.seller_id}
        />
      </div>
      <div className="form-check mb-2">
        <input
          className="form-check-input"
          type="checkbox"
          name="sync_type"
          value="instant"
          id="instant"
          onClick={() => setSelectInstant(prev => !prev)}
          checked={selectInstant}
        />
        <label className="form-check-label" htmlFor="instant">
          Instant
        </label>
      </div>
      <div className="mb-3">
        <label className="form-label">Product IDs</label>
        <textarea
          name="product_ids"
          className="form-control"
          onChange={handleChange}
          value={fields?.product_ids || ""}
          placeholder={
            selectInstant
              ? "(e.g: product 1,...)"
              : "(e.g: product1, product2..."
          }
        />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="button"
          className="ms-2"
          disabled={loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </PrimaryPopup>
  );
}

export default ProductDuplicatePopup;
