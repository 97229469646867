import TopHeading from "components/Common/TopHeading";
import React, { useCallback } from "react";
import {
  CardBody,
  Card,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import deleteIcon from "../../assets/images/delete-icon-white.svg";
import { useEffect, useState, useRef } from "react";
import {
  getAllProducts,
  deleteProduct,
  markProductsAsAdult,
  banFromMerchant,
  importDisableGMCProduct,
  revertDeletedProduct,
  updateGMCProduct,
  addTagsToProducts,
} from "services/ProductsServices";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { useNavigate } from "react-router-dom";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";
import ProductActionPopup from "components/Common/Popups/ProductActionPopup.js";
import editIcon from "../../assets/images/edit-icon-white.svg";
import tickIcon from "assets/images/tick-icon.svg";
import cancelIcon from "assets/images/cancle-icon.svg";
import ProductGMCActionPopup from "components/Common/Popups/ProductGMCActionPopup";
import { ProductTagsAddPopup } from "components/Common/Popups/ProductTagsAddPopup";
import ProductDuplicatePopup from "components/Common/Popups/ProductDuplicate";
import DownloadFilesById from "components/Common/Popups/DownloadFilesById";
import DownloadAllProductsCsv from "components/Common/Popups/DownloadAllProductsCsv";
import MoveProductsPopupToSeller from "components/Common/Popups/MoveProductsPopupToSeller";
import MerchantLabelPopup from "components/Common/Popups/MerchantLabelPopup";
import UpdateProductOptionsPopup from "components/Common/Popups/UpdateProductOptionsPopup";
import AliExpressProductSyncPopup from "components/Common/Popups/AliExpressProductSyncPopup";
export default function AllProducts() {
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [inputValue, setInputValue] = useState("");
  const [productDataBySku, setProductDataBySku] = useState();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [actionType, setActionType] = useState("");
  const [searchVal, setSearch] = useState(null);
  const [popupType, setPopupType] = useState("");
  const [importFile, setImportFile] = useState();
  const [isDisableFromGMC, setIsDisableFromGMC] = useState(false);
  const [isEnableDisableFromGMC, setIsEnableDisableFromGMC] = useState();
  const [disabledEnabledBulk, setDisabledEnabledBulk] = useState({
    is_enable: 1,
    action_by: "csv_file",
  });
  const [isDownloadDigital, setDownloadDigital] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isAddTag, setIsAddTag] = useState(false);
  const [tagFields, setTagFields] = useState({});
  const [isallProductsCsv, setIsallProductsCsv] = useState(false);
  const [isMoveProducts, setIsMoveProducts] = useState(false);
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const params = new URLSearchParams(window?.location.search);
  const history = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isMerchantLabel, setisMerchantLabel] = useState(false);
  const [isUpdateProductOptions, setIsUpdateProductOptions] = useState(false);
  const [isAliExpressSync, setIsAliExpressSync] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    setPageTitle("Products - Inspire Uplift");
  }, []);

  useEffect(() => {
    // Check if start and end dates exist in URL parameters
    const startDateParam = params.get("start");
    const endDateParam = params.get("end");

    // Check if search parameter exists
    const searchParam = params.get("search") || undefined;
    const page = params.get("page") || undefined;
    setPageNo(page || 1);
    if (startDateParam || endDateParam || searchParam || params.get("page")) {
      if (startDateParam && endDateParam) {
        // If start and end dates exist, use them in the request
        const startDate = moment(startDateParam).format("YYYY-MM-DD");
        const endDate = moment(endDateParam).format("YYYY-MM-DD");
        setLoading(true);
        getProducts(page, null, startDate, endDate);
      } else if (searchParam) {
        // If only search parameter exists, use it in the request
        setLoading(true);
        getProducts(page, searchParam, null, null);
        setSearch(searchParam);
      } else {
        setLoading(true);
        // If no parameters exist, make a regular request
        getProducts(page, null, null, null);
      }
    }
  }, [window?.location?.search]);

  const getProducts = (page, search, start, end) => {
    getAllProducts(page, search, start, end)
      .then(res => {
        setProductsData(res.data.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const val = e.target?.value;
    if (!val.match(/^[0-9]+$/)) {
      setSearch("");
      return false;
    } else {
      setSearch(val);
    }
  };

  const handleRevertProduct = id => {
    toast.dismiss();
    setLoading(true);
    revertDeletedProduct([id])
      .then(res => {
        const startDateParam = params.get("start") || undefined;
        const endDateParam = params.get("end") || undefined;
        // Check if search parameter exists
        const searchParam = params.get("search") || undefined;

        getProducts(pageNo, searchParam, startDateParam, endDateParam);
        PrimaryToast({
          type: "success",
          message: "Product revert successfully",
        });
      })
      .catch(err => {
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message,
        });
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "index",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "id",
      text: "Product ID",
      formatter: (cell, row) => <div className="font-semi-bold">{row.id}</div>,
    },
    {
      dataField: "title",
      text: "Product Name",
      formatter: (cell, row) => (
        <div className="product-title-container" style={{ width: "300px" }}>
          {row?.title}
        </div>
      ),
    },
    {
      dataField: "seller_group",
      text: "Seller Group",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`status w-max-content ${
            row.status === 2
              ? "success-status"
              : row.status === 5
              ? "danger-status"
              : ""
          }`}
        >
          {row.status === 2 && "Approved"}
          {row.status === 5 && "Deactivated"}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) =>
        row.status !== "Delete" ? (
          <div className="d-flex align-items-center flex-wrap">
            <Button
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                setIsShowPopup(true);
                setSelectedId(row.id);
                setPopupType("edit-category");
              }}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </Button>
            {permissions.includes("product-delete") && row.status == 6 ? (
              <Button
                color="primary"
                className="me-1"
                disabled={loading}
                onClick={() => handleRevertProduct(row.id)}
              >
                Revert
              </Button>
            ) : null}
            {permissions.includes("product-delete") && row.status != 6 ? (
              <div
                className="action-btn delete-btn-bg cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  setSelectedId(row.id);
                  setActionType("delete");
                  setShowDeleteModal(true);
                }}
              >
                <img src={deleteIcon} className="" alt="delete icon" />
              </div>
            ) : null}
            {row?.adult_status == 0 &&
              permissions?.includes("mark-adult-products") && (
                <Button
                  color="primary"
                  className="ms-1"
                  disabled={loading}
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedId(row.id);
                    setActionType("adult");
                    setShowDeleteModal(true);
                  }}
                >
                  Mark as Adult
                </Button>
              )}
            {row?.is_merchant != null &&
              permissions?.includes("ban-gmc-product") && (
                <Button
                  className={`ms-1 d-flex align-items-center`}
                  color="primary"
                  disabled={loading}
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedId(row.id);
                    setActionType("ban");
                    setIsEnableDisableFromGMC(row?.is_merchant == 0 ? 1 : 0);
                    setShowDeleteModal(true);
                  }}
                >
                  <img
                    src={row.is_merchant == 0 ? tickIcon : cancelIcon}
                    className="pe-2"
                    alt="g.m.c action"
                  />
                  <span>
                    {row.is_merchant == 0
                      ? "Enable"
                      : row?.is_merchant == 1
                      ? "Disable"
                      : ""}{" "}
                    From G.M.C
                  </span>
                </Button>
              )}
          </div>
        ) : null,
    },
  ];

  const handleUpdatedData = response => {
    const startDateParam = params.get("start") || undefined;
    const endDateParam = params.get("end") || undefined;

    const searchParam = params.get("search") || undefined;

    getProducts(pageNo, searchParam, startDateParam, endDateParam);
    setSelectedId("");
    setActionType("");
    setShowDeleteModal(false);
    PrimaryToast({ type: "success", message: response?.data?.message });
  };

  const handleAction = () => {
    toast.dismiss();
    setLoading(true);
    if (actionType == "delete") {
      deleteProduct(selectedId)
        .then(response => {
          handleUpdatedData(response);
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
          console.log(err);
        });
    } else if (actionType == "adult") {
      markProductsAsAdult(selectedId)
        .then(response => {
          handleUpdatedData(response);
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
          console.log(err);
        });
    } else if (actionType == "ban") {
      banFromMerchant(selectedId, isEnableDisableFromGMC)
        .then(response => {
          setIsEnableDisableFromGMC("");
          handleUpdatedData(response);
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
          console.log(err);
        });
    }
  };

  const data = productsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      let params = new URLSearchParams(window.location.search);
      if (searchVal) {
        params.set("search", searchVal);
      } else {
        params.delete("search");
      }
      if (params.get("page")) {
        params.delete("page");
      }
      let newUrl = `${window.location.pathname}?${params.toString()}`;
      history(newUrl, { replace: true });
    }
  };

  const handlePopup = () => {
    setIsDisableFromGMC(!isDisableFromGMC);
    setImportFile();
    setDisabledEnabledBulk({
      is_enable: 1,
      action_by: "csv_file",
    });
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setImportFile(file);
    } else {
      PrimaryToast({
        type: "error",
        message: "Please select a valid CSV file.",
      });
    }
  };

  const handleImportGMCFile = async () => {
    setLoading(true);
    if (disabledEnabledBulk?.action_by == "csv_file") {
      try {
        const response = await importDisableGMCProduct(
          importFile,
          disabledEnabledBulk?.is_enable
        );
        if (response.status == 200) {
          PrimaryToast({
            type: "success",
            message: response?.data?.message,
          });
          handlePopup();
        }
      } catch (err) {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      } finally {
        setLoading(false);
      }
    } else if (disabledEnabledBulk?.action_by == "seller_id") {
      try {
        const response = await updateGMCProduct(
          disabledEnabledBulk?.seller_id,
          disabledEnabledBulk?.is_enable
        );
        if (response.status == 200) {
          PrimaryToast({
            type: "success",
            message: response?.data?.message,
          });
          handlePopup();
        }
      } catch (err) {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGMCPopupFieldsChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setDisabledEnabledBulk({ ...disabledEnabledBulk, [name]: value });
  };

  const handleTagChange = useCallback(e => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "tag") {
      if (/^[a-zA-Z0-9\s]+$/.test(value)) {
        setTagFields(pre => ({
          ...pre,
          [name]: value,
        }));
      } else {
        return;
      }
    } else {
      setTagFields(pre => ({
        ...pre,
        [name]: value,
      }));
    }
  }, []);

  const handleToggleTags = useCallback(() => {
    setIsAddTag(false);
    setTagFields({});
  }, []);

  const handleAddTag = e => {
    e.preventDefault();
    if (!tagFields?.tag?.trim()) {
      PrimaryToast({
        type: "error",
        message: "Tag is required",
      });
      return;
    }
    if (!tagFields?.product_ids?.trim()) {
      PrimaryToast({
        type: "error",
        message: "Product IDs are required",
      });
      return;
    }

    const payload = {
      tag: tagFields?.tag?.toLowerCase() || undefined,
      product_ids: tagFields?.product_ids
        ?.split(",")
        ?.map(item => Number(item)),
    };
    setLoading(true);
    addTagsToProducts(payload)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        handleToggleTags();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleDuplicateToggle = () => {
    setIsDuplicate(!isDuplicate);
  };

  const handleToggleDigitalFile = () => {
    setDownloadDigital(!isDownloadDigital);
  };

  const handleToggleIsAllProductCsv = () => {
    setIsallProductsCsv(!isallProductsCsv);
  };

  const handleToggleIsMoveProducts = () => {
    setIsMoveProducts(!isMoveProducts);
  };
  const handleToggleMerchantLabel = () => {
    setisMerchantLabel(!isMerchantLabel);
  };

  const handleToggleUpdateProductOptions = () => {
    setIsUpdateProductOptions(!isUpdateProductOptions);
  };

  const handleToggleAliExpressSync = () => {
    setIsAliExpressSync(!isAliExpressSync);
  };
  return (
    <div className="page-content">
      {isDisableFromGMC && (
        <ProductGMCActionPopup
          isOpen={isDisableFromGMC}
          handleClick={handlePopup}
          handleFileUpload={handleFileUpload}
          handleSave={handleImportGMCFile}
          loading={loading}
          disabledEnabledBulk={disabledEnabledBulk}
          handleChange={handleGMCPopupFieldsChange}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          title={
            actionType == "delete"
              ? "Are you sure to delete this product?"
              : actionType == "adult"
              ? "Are you sure to mark  this product adult?"
              : actionType == "ban"
              ? `Are you sure to ${
                  isEnableDisableFromGMC == 1 ? "Unban" : "ban"
                }    this product from  Merchant?`
              : ""
          }
          onConfirm={handleAction}
          onCancel={() => {
            setSelectedId("");
            setActionType("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3">
        <TopHeading heading={"All Products"} />
      </div>
      <Card>
        <CardBody>
          <div className="mb-3 d-flex align-items-start gap-4 justify-content-between all-products-container">
            <PrimarySearch
              handleKeyPress={handleKeyPress}
              handleChange={handleSearch}
              placeholder={"Search by product ID"}
              value={searchVal}
            />
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              innerRef={dropdownRef}
            >
              <DropdownToggle caret color="primary">
                Actions
              </DropdownToggle>
              <DropdownMenu style={{ width: "300px" }}>
                {permissions.includes("aliexpress_product") && (
                  <DropdownItem
                    onClick={() => {
                      handleToggleAliExpressSync();
                      setDropdownOpen(false);
                    }}
                  >
                    AliExpress Product Sync
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() => {
                    handleToggleUpdateProductOptions();
                    setDropdownOpen(false);
                  }}
                >
                  Update Product Options
                </DropdownItem>
                {permissions.includes("ban-gmc-product") && (
                  <DropdownItem
                    onClick={() => {
                      handleToggleMerchantLabel();
                      setDropdownOpen(false);
                    }}
                  >
                    Merchant Labels
                  </DropdownItem>
                )}
                {permissions.includes("seller-duplicate") && (
                  <DropdownItem
                    onClick={() => {
                      handleToggleIsMoveProducts();
                      setDropdownOpen(false);
                    }}
                  >
                    Move Products
                  </DropdownItem>
                )}
                {permissions.includes("product-list") && (
                  <DropdownItem
                    onClick={() => {
                      handleToggleIsAllProductCsv();
                      setDropdownOpen(false);
                    }}
                  >
                    Product CSV
                  </DropdownItem>
                )}
                {permissions.includes("digital-product") && (
                  <DropdownItem
                    onClick={() => {
                      handleToggleDigitalFile();
                      setDropdownOpen(false);
                    }}
                  >
                    Download Digital Product
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() => {
                    handleDuplicateToggle();
                    setDropdownOpen(false);
                  }}
                >
                  Duplicate Product
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setIsAddTag(true);
                    setDropdownOpen(false);
                  }}
                >
                  Add Tag
                </DropdownItem>
                {permissions?.includes("ban-gmc-product") && (
                  <DropdownItem
                    onClick={() => {
                      setIsDisableFromGMC(!isDisableFromGMC);
                      setDropdownOpen(false);
                    }}
                  >
                    Enable/Disable Products From G.M.C
                  </DropdownItem>
                )}
                {permissions.includes("products-delete-by-id") && (
                  <DropdownItem
                    onClick={() => {
                      setPopupType("seller id");
                      setIsShowPopup(!isShowPopup);
                      setDropdownOpen(false);
                    }}
                  >
                    Delete Product By seller ID
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() => {
                    setPopupType("sku");
                    setIsShowPopup(!isShowPopup);
                    setDropdownOpen(false);
                  }}
                >
                  Search by SKU
                </DropdownItem>
                {permissions.includes("product-delete") && (
                  <DropdownItem
                    onClick={() => {
                      setPopupType("product id");
                      setIsShowPopup(!isShowPopup);
                      setDropdownOpen(false);
                    }}
                  >
                    Delete/Disable Product By ID
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>

          <div>
            <PrimaryTable
              columns={columns}
              data={data}
              keyField="id"
              responsive
              bordered={false}
            />
            {productsData?.length > 0 && pageCount > 1 && (
              <PrimaryPagination
                pageNo={pageNo}
                setPageNo={setPageNo}
                pageCount={pageCount}
              />
            )}
          </div>
        </CardBody>
      </Card>
      {isShowPopup && (
        <ProductActionPopup
          isOpen={isShowPopup}
          setInputValue={setInputValue}
          inputValue={inputValue}
          productDataBySku={productDataBySku}
          popupType={popupType}
          setProductDataBySku={setProductDataBySku}
          selectedId={selectedId}
          handleClick={() => {
            setPopupType("all-products");
            setIsShowPopup(false);
            setInputValue();
            setProductDataBySku();
            setSelectedId("");
          }}
        />
      )}
      {isAddTag && (
        <ProductTagsAddPopup
          handleClose={handleToggleTags}
          isOpen={isAddTag}
          tagFields={tagFields}
          handleChange={handleTagChange}
          handleAddTag={handleAddTag}
        />
      )}
      {isDuplicate && (
        <ProductDuplicatePopup
          handleClick={handleDuplicateToggle}
          isOpen={isDuplicate}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {isDownloadDigital && (
        <DownloadFilesById
          path={"digital-product-download"}
          isOpen={isDownloadDigital}
          handleClick={handleToggleDigitalFile}
        />
      )}
      {isallProductsCsv && (
        <DownloadAllProductsCsv
          path={"product-list"}
          isOpen={isallProductsCsv}
          handleClick={handleToggleIsAllProductCsv}
        />
      )}
      {isMoveProducts && (
        <MoveProductsPopupToSeller
          handleClick={handleToggleIsMoveProducts}
          isOpen={isMoveProducts}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {isMerchantLabel && (
        <MerchantLabelPopup
          handleClick={handleToggleMerchantLabel}
          isOpen={isMerchantLabel}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {isUpdateProductOptions && (
        <UpdateProductOptionsPopup
          handleClick={handleToggleUpdateProductOptions}
          isOpen={isUpdateProductOptions}
          loading={loading}
          setLoading={setLoading}
          handleFileUpload={handleFileUpload}
        />
      )}
      {isAliExpressSync && (
        <AliExpressProductSyncPopup
          handleClick={handleToggleAliExpressSync}
          isOpen={isAliExpressSync}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </div>
  );
}
