import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import { Button } from "reactstrap";
import LoadingButton from "../buttons/LoadingButton";

const PhoneDetailsFormPopup = ({
  isOpen,
  handlePopup,
  phoneFields,
  handlePhoneFieldsChange,
  handlePlaceAEOrder,
  loading,
}) => {
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handlePopup}
      title={"Add Contact Info"}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center w-100 search-product-by-ids-popup-container"
    >
      <PrimaryInput
        name={"phone"}
        value={phoneFields?.phone || ""}
        handleChange={handlePhoneFieldsChange}
        placeholder={"Enter phone number"}
      />
      <div className="mt-2">
        <PrimaryInput
          name={"phone_country"}
          value={phoneFields?.phone_country || ""}
          handleChange={handlePhoneFieldsChange}
          placeholder={"Enter country calling code"}
        />
      </div>
      <div className="d-flex align-items-center justify-content-end mt-4">
        <Button className="me-3" onClick={handlePopup}>
          Cancel
        </Button>
        <LoadingButton
          text={"Place Order"}
          handleClick={handlePlaceAEOrder}
          color="primary"
          isLoading={loading}
        />
      </div>
    </PrimaryPopup>
  );
};

export default PhoneDetailsFormPopup;
