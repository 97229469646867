import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders, siteApiUrl } from "./BaseService";

export const getAllPODLibrary = async params => {
  const res = await getApi({
    url: `admin/pod/library`,
    data: {
      ...params,
      is_admin: true,
    },
  });
  return res.data;
};

export const createPODAsset = (name, previewFile, designFile) => {
  console.log("Sending Data:", name, previewFile, designFile);

  const formData = new FormData();
  if (name) formData.append("name", name);
  if (previewFile) formData.append("preview_file", previewFile);
  if (designFile) formData.append("file", designFile);
  formData.append("is_admin", "true");

  return axiosInstance({
    url: `${siteApiUrl}admin/pod/library/create`,
    method: "POST",
    data: formData,
    withCredentials: true,
    headers: {
      ...getHttpHeaders(),
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updatePODAsset = (id, name, previewFile, designFile) => {
  const formData = new FormData();
  if (name) {
    formData.append("name", name);
  }
  if (previewFile) {
    formData.append("preview_file", previewFile);
  }
  if (designFile) {
    formData.append("file", designFile);
  }

  formData.append("is_admin", "true");

  return axiosInstance({
    url: `${siteApiUrl}admin/pod/library/update/${id}`,
    method: "POST",
    data: formData,
    headers: {
      ...getHttpHeaders(),
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
};

export const deletePODAsset = ids => {
  if (!Array.isArray(ids)) {
    console.error("deletePODAsset: Expected an array, but got", ids);
    ids = [ids];
  }

  const formData = new FormData();
  ids.forEach(id => {
    formData.append("ids[]", id);
    console.log("id pod", id);
  });

  return axiosInstance({
    url: `${siteApiUrl}admin/pod/library/destroy`,
    method: "DELETE",
    data: {
      ids,
      is_admin: true,
    },
    headers: getHttpHeaders(),
    withCredentials: true,
  });
};
