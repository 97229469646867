import React from "react";
import PrimaryPopup from "../../../components/Common/Popups/PrimaryPopup";
const JobsErrorPopup = ({ isModalOpen, setIsModalOpen, error, type }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(error || "");
    setIsModalOpen(false);
  };

  return (
    <PrimaryPopup
      isOpen={isModalOpen}
      handleClick={() => setIsModalOpen(false)}
      title={`${type} Message`}
      isScrollable={true}
      className="modal-dialog-centered"
    >
      <div
        className="p-3 bg-white border rounded h-70vh"
        style={{
          maxHeight: "400px",
          overflowY: "auto",
          wordBreak: "break-word",
        }}
      >
        {(error || "").split("\n").map((line, index) => (
          <p key={index} className="text-black m-0">
            {line}
          </p>
        ))}
      </div>

      <div className="d-flex justify-content-end mt-3">
        <button className="btn btn-primary me-2 px-4" onClick={handleCopy}>
          Copy
        </button>
        <button
          className="btn btn-secondary px-4"
          onClick={() => setIsModalOpen(false)} // Use prop instead of setIsOpen
        >
          Close
        </button>
      </div>
    </PrimaryPopup>
  );
};

export default JobsErrorPopup;
