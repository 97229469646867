import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Card, CardBody } from "reactstrap";
import DateRangePicker from "components/Common/DateRangePicker";
import { useNavigate } from "react-router-dom";
import TopHeading from "components/Common/TopHeading";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { setPageTitle } from "utils/pageTitle";
import { getJobs, retryJobs } from "services/JobsServices";
import retryIcon from "assets/images/retry-icon.jpg";
import PrimarySelect from "components/Common/PrimarySelect";
import ResetButton from "components/Common/ResetButton";
import PrimarySearch from "components/Common/PrimarySearch";
import useToggle from "hooks/useToggle";
import JobsErrorPopup from "../../components/Common/Popups/JobsErrorPopup";
function Jobs() {
  const history = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState();
  const [search, setSearch] = useState("");
  const [showCalendar, setShowCalendar] = useToggle(false);
  const [isModalopen, setIsModalopen] = useState(false);
  const [jobError, setJobError] = useState("");
  const [errorType, setErrorType] = useState("");
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleOpenModal = (errorMessage, type) => {
    setJobError(errorMessage);
    setErrorType(type);
    setIsModalopen(true);
  };

  const [isApplyDateFilter, setIsApplyDateFilter] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setPageTitle("Jobs  - Inspire Uplift");
  }, []);

  useEffect(() => {
    const status = queryParams.get("status") || "pending";
    const page = Number(queryParams.get("page")) || 1; // Ensure it's a number
    const searchVal = queryParams.get("search") || undefined;

    const today = moment().format("YYYY-MM-DD");
    const startDateParam = queryParams.get("start_date") || today;
    const endDateParam = queryParams.get("end_date") || today;

    setDates([
      {
        startDate: moment(startDateParam, "YYYY-MM-DD").toDate(),
        endDate: moment(endDateParam, "YYYY-MM-DD").toDate(),
        key: "selection",
      },
    ]);

    setSearch(searchVal);
    setStatus(status);
    setPageNo(page);
    setLoading(true);

    fetchData(status, page, searchVal, startDateParam, endDateParam);
  }, [queryParams.toString()]); // Using queryParams.toString() to trigger re-runs

  const fetchData = (status, page, search, startDateParam, endDateParam) => {
    const start_date = startDateParam
      ? moment(startDateParam || new Date())
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    const end_date = endDateParam
      ? moment(endDateParam || new Date())
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    getJobs(status, page, search, start_date, end_date)
      .then(res => {
        setJobsData(res.data.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };
  const baseColumns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "queue",
      text: "Queue Name",
    },
    {
      dataField: "payload",
      text: "Payload",
      formatter: (cell, row) => (
        <div className="text-truncate" style={{ maxWidth: "200px" }}>
          <button
            onClick={() => handleOpenModal(row.payload, "Payload")}
            className="btn btn-link text-primary p-0 m-0"
          >
            {row.payload}
          </button>
        </div>
      ),
    },
  ];

  const pendingColumns = [
    {
      dataField: "attempts",
      text: "Attempts",
    },
  ];
  const failedColumns = [
    {
      dataField: "exception",
      text: "Exception",
      formatter: (cell, row) => (
        <div className="text-truncate" style={{ maxWidth: "400px" }}>
          <button
            onClick={() => handleOpenModal(row.exception, "Exception")}
            className="btn btn-link text-primary p-0 m-0"
          >
            {row.exception}
          </button>
        </div>
      ),
    },
    {
      dataField: "failed_at",
      text: "Failed At",
      formatter: (cell, row) => (
        <div className="text-truncate" style={{ maxWidth: "400px" }}>
          {row.failed_at}
        </div>
      ),
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: (cell, row) => (
        <img
          src={retryIcon}
          height={40}
          width={40}
          alt="retry icon"
          className="cursor-pointer"
          onClick={() => handleRetryJob(row.id)}
        />
      ),
    },
  ];

  const columns = [
    ...baseColumns,
    ...(queryParams?.get("status") === "failed"
      ? failedColumns
      : pendingColumns),
  ];
  const data = jobsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  let nonSelected = jobsData?.map(item => {
    if (
      item?.is_active == 3 ||
      ((item.verify_seller === 0 || item.verify_seller === 1) &&
        item.is_active === 2)
    ) {
      return item.id;
    }
  });

  const handleRetryJob = async (ids, type = "single") => {
    const queueIds = type === "single" ? [ids] : ids;

    setLoading(true);

    try {
      const res = await retryJobs(queueIds);

      if (res.status === 200) {
        PrimaryToast({
          type: "success",
          message: "Jobs have been successfully retried.",
        });

        if (type === "multiple") {
          setSelectedRowKeys([]);
        }

        if (
          selectedRowKeys.length === jobsData.length &&
          pageNo === pageCount
        ) {
          queryParams.set("page", pageNo - 1);
          history(`?${queryParams.toString()}`);
        } else {
          fetchData(
            status,
            pageNo,
            search,
            isApplyDateFilter
              ? moment(dates[0].startDate).format("YYYY-MM-DD")
              : undefined,
            isApplyDateFilter
              ? moment(dates[0].endDate).format("YYYY-MM-DD")
              : undefined
          );
        }
      }
    } catch (error) {
      PrimaryToast({ type: "error", message: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  const handleOnSelectAll = (isSelected, rows, e) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
    } else {
      these;
      setSelectedRowKeys([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    nonSelectableClasses: "hide-checkbox",
    nonSelectable: nonSelected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
      }
    },
    onSelectAll: handleOnSelectAll,
    selected: selectedRowKeys,
  };

  const handleChange = e => {
    setSearch(e.target.value);
  };
  const handleEnterKeyPress = e => {
    if (e.key == "Enter") {
      handleFilter();
    }
  };
  const handleFilter = () => {
    if (status) {
      queryParams?.set("status", status);
    } else {
      queryParams.delete("status");
    }
    if (search) {
      queryParams?.set("search", search);
    } else {
      queryParams.delete("search");
    }
    if (isApplyDateFilter) {
      queryParams.set(
        "start_date",
        moment(dates[0].startDate).format("YYYY-MM-DD")
      );
      queryParams.set(
        "end_date",
        moment(dates[0].endDate).format("YYYY-MM-DD")
      );
    }
    queryParams.delete("page");
    history(`?${queryParams.toString()}`);
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };
  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"loading"} />}
      <div className="d-flex align-items-center justify-content-between mb-3">
        <TopHeading heading={"Queues"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex gap-2">
            <PrimarySelect
              value={status || ""}
              radius={"rounded-full"}
              name="status"
              handleChange={e => {
                setStatus(e.target.value);
              }}
              options={[
                {
                  name: "Failed",
                  value: "failed",
                },
                {
                  name: "Pending",
                  value: "pending",
                },
              ]}
            />
            <PrimarySearch
              placeholder={"Search jobs"}
              handleChange={handleChange}
              value={search || ""}
              handleKeyPress={handleEnterKeyPress}
            />
            <div className="position-relative ms-1">
              <div
                className="form-control cursor-pointer rounded-full"
                style={{ color: "#74788D", width: "max-content" }}
                onClick={() => setShowCalendar(true)}
              >
                {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                {moment(dates[0].endDate).format("DD MMM YYYY")}
              </div>

              {showCalendar && (
                <>
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{ zIndex: "10" }}
                    onClick={() => setShowCalendar(false)}
                  ></div>
                  <DateRangePicker
                    dates={dates}
                    handleDatePicker={handleDatePicker}
                    handleSubmit={() => {
                      setShowCalendar(false);
                      setIsApplyDateFilter(true);
                    }}
                    // minDate={new Date()}
                    maxDate={new Date()}
                  />
                </>
              )}
            </div>
            <Button
              color="primary"
              className={`btn-rounded`}
              onClick={handleFilter}
              disabled={!status}
            >
              Apply Filter
            </Button>
            {queryParams?.toString()?.length > 0 && <ResetButton />}
            {selectedRowKeys?.length > 0 && (
              <div className="ms-auto">
                <Button
                  color="primary"
                  onClick={e => handleRetryJob(selectedRowKeys, "multiple")}
                >
                  Retry Queues
                </Button>
              </div>
            )}
          </div>
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              selectRow={selectRow}
              keyField="id"
            />
          </div>
          {jobsData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
      {isModalopen && (
        <JobsErrorPopup
          isModalOpen={isModalopen}
          setIsModalOpen={setIsModalopen}
          error={jobError}
          type={errorType}
        />
      )}
    </div>
  );
}

export default Jobs;
