import React, { useState } from "react";
import { Button } from "reactstrap";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import PrimaryToast from "../Toast/ToastMessage";
import { AliExpressProductSync } from "services/ProductsServices";

function AliExpressProductSyncPopup({
  handleClick,
  isOpen,
  loading,
  setLoading,
}) {
  const [fields, setFields] = useState({
    ae_product_id: "",
    seller_id: "",
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFields(prev => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setFields({
      ae_product_id: "",
      seller_id: "",
    });
    handleClick();
  };

  const handleSave = async () => {
    const { ae_product_id, seller_id } = fields;

    if (!ae_product_id || !seller_id) {
      PrimaryToast({
        type: "error",
        message: "All fields are required",
      });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        ae_product_id: Number(ae_product_id),
        seller_id: Number(seller_id),
      };

      if (isNaN(payload.ae_product_id) || isNaN(payload.seller_id)) {
        throw new Error("Product ID and Seller ID must be valid numbers");
      }

      const response = AliExpressProductSync(payload);

      PrimaryToast({
        type: "success",
        message: response.data?.message || "Product synced successfully!",
      });
      handleClose();
    } catch (error) {
      PrimaryToast({
        type: "error",
        message:
          error?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryPopup
      title="AliExpress Product Sync"
      handleClick={handleClick}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
      isOpen={isOpen}
    >
      <div className="mb-3">
        <PrimaryInput
          name="ae_product_id"
          handleChange={handleChange}
          label="AliExpress Product ID"
          placeholder="Enter AliExpress product ID (e.g., 1005008154048088)"
          value={fields.ae_product_id}
          type="number"
        />
      </div>

      <div className="mb-3">
        <PrimaryInput
          name="seller_id"
          handleChange={handleChange}
          label="Seller ID"
          placeholder="Enter seller ID (e.g., 5)"
          value={fields.seller_id}
          type="number"
        />
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          className="ms-2"
          disabled={loading || !fields.ae_product_id || !fields.seller_id}
          onClick={handleSave}
        >
          {loading ? "Syncing..." : "Sync Product"}
        </Button>
      </div>
    </PrimaryPopup>
  );
}

export default AliExpressProductSyncPopup;
