import axios from "axios";

const instance = axios.create();

// Request interceptor: remove Content-Type for FormData requests only for the specific domain
instance.interceptors.request.use(
  config => {
    console.log(config, "check config", config.data instanceof FormData);
    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"];
    }
    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor remains unchanged
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/forgot-password"
      ) {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
