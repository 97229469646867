import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CardBody, Card, Button } from "reactstrap";
import CommentSection from "components/Seller/CommentSection";
import {
  getSingleStoreData,
  getAllPayoutMethods,
  getTransactionAdjustment,
  updateSellerStore,
  deletePayoutMethod,
  createTransactionAdjustment,
  getDownloadTransactionCsv,
  getHyperwalletUser,
  updateHyperwalletUser,
  resetPasswordLink,
  getAllSellerBalances,
  syncMerchants,
  askKyc,
} from "services/SellerStoreServices";
import { getStakeholderList } from "services/SellerPayoutMethodService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PayoutMethodSection from "components/Seller/PayoutMethodSection";
import TransactionAdjusmentComponent from "components/Seller/TransactionAdjustmentSection";
import HyperwalletUser from "components/Seller/HyperwalletUser";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import StakeHolderTable from "components/Seller/StakeHolderTable";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import BalanceSection from "components/Seller/BalanceSection";
import SellerInformationForm from "components/Seller/SellerInformationForm";
import GlobalService from "services/GlobalService";
import { deleteAdjusment } from "services/SellerStoreServices";
import ExportButton from "components/Common/ExportButton";
import BalanceAdjusmentComponent from "components/Seller/BalanceAdjusmentComponent";
import KycVerificationBadge from "components/Seller/KycVerificationBadge";

const EditStore = () => {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [storeEmail, setEmail] = useState("");
  const [payoutMethodsList, getPayoutMethodList] = useState([]);
  const [payoutPage, setPayoutPage] = useState(1);
  const [transactionAdjusmentData, setTransactionAdjusmentData] = useState([]);
  const [transPageCount, setTransPageCount] = useState();
  const [adjustFields, setAdjustFields] = useState({
    effect: 0,
  });
  const [popupTitle, setPopupTitle] = useState("");
  const [adjustmentPageNo, setAdjustMentPageNo] = useState(1);
  const [hyperwalletFields, setHyperwalletFields] = useState({});
  const [ordersExist, setOrdersExist] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [stakeHoldersData, setStakeHoldersData] = useState([]);
  const [isProvinceText, setIsProvinceText] = useState(false);
  const [sellerBalances, setSellerBalances] = useState({});
  const [statesData, setStates] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [kycStatus, setKycStatus] = useState("");
  const [marchantValue, setMarchantValue] = useState();
  const [initialHyperwalletFields, setInitialHyperwalletData] = useState();
  const [merchantSyncVal, setMerchantSyncVal] = useState("");
  const permissions = useSelector(state => state?.auth?.user?.permissions);
  const hasTransactionPer = permissions?.some(
    p => p.name === "transaction-adjustment"
  );
  const hasStakeholderEditPer = permissions?.some(
    p => p.name === "stakeholder-edit"
  );
  const hasSellerBalanceAdjust = permissions?.some(
    p => p.name === "seller-balances"
  );
  const history = useNavigate();
  const location = useParams();
  const { id } = location;
  const globalService = GlobalService();

  useEffect(() => {
    setPageTitle("Edit Seller Store - Inspire Uplift");
  }, []);

  useEffect(() => {
    getCountries();
    getStates();
  }, []);

  const getStates = () => {
    globalService
      .getStates()
      .then(response => {
        setStates([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  const getCountries = () => {
    globalService
      .getCountries()
      .then(response => {
        setCountriesData([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  useEffect(() => {
    getAllSellerBalances(id)
      .then(res => {
        setSellerBalances(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleClickKyc = () => {
    askKyc(id)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err?.message,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    getSingleStoreData(id)
      .then(res => {
        {
          let storeInfo = {
            store_name: res?.data?.seller?.business_name,
            seo_title: res?.data?.seller?.seo_title,
            seo_description: res?.data?.seller?.seo_description,
            slug: res?.data?.seller?.slug,
            store_email: res?.data?.seller?.email,
            first_name: res?.data?.seller?.seller_staff?.first_name,
            last_name: res?.data?.seller?.seller_staff?.last_name,
            seller_group: res?.data?.seller?.seller_group_id,
          };
          setEmail(res?.data?.seller?.email);
          setFields(storeInfo);
          setKycStatus(res?.data?.seller?.kyc_status);
          let sellerGroups = res?.data?.seller?.seller_group_id?.map(item => {
            const { name, id } = item;
            return {
              label: name,
              value: id,
            };
          });
          let isOrders = res?.data?.seller?.total_orders > 0 ? true : false;
          setOrdersExist(isOrders);
          setSelectedOptions(sellerGroups[0]);
          setMarchantValue(res?.data?.seller?.merchants);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  useEffect(() => {
    getStakeholderList(id)
      .then(res => {
        setStakeHoldersData(res?.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getHyperwalletUser(id)
      .then(res => {
        setHyperwalletFields({
          ...res?.data,
          countryOfNationality: res?.data?.countryOfNationality || undefined,
        });
        setInitialHyperwalletData({
          ...res?.data,
          countryOfNationality: res?.data?.countryOfNationality || undefined,
        });
        if (res?.data?.country !== "US") {
          setIsProvinceText(true);
        } else {
          setIsProvinceText(false);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  useEffect(() => {
    fetchAdjustmentTransations(adjustmentPageNo);
  }, [adjustmentPageNo]);

  const fetchAdjustmentTransations = page => {
    getTransactionAdjustment(id, page)
      .then(res => {
        setTransactionAdjusmentData(res?.data?.data);
        setTransPageCount(res?.data?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  useEffect(() => {
    getAllPayoutMethods(id, payoutPage)
      .then(res => {
        getPayoutMethodList(res.data);
      })
      .catch(err => console.log(err));
  }, [payoutPage]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const handleDownloadCsv = async () => {
    const response = await getDownloadTransactionCsv(id);
    return response;
  };

  const handleDeletePayout = () => {
    toast.dismiss();
    setLoading(true);
    const filterArr = payoutMethodsList?.data?.filter(
      item => item.id !== selectedId
    );
    deletePayoutMethod(selectedId)
      .then(res => {
        if (res.status === 200) {
          if (filterArr?.length == 0 && payoutPage > 1) {
            setPayoutPage(payoutPage - 1);
          } else {
            getPayoutMethodList({
              ...payoutMethodsList,
              data: filterArr,
            });
          }

          setSelectedId("");
          setShowDeleteModal(false);
          setPopupTitle("");
          PrimaryToast({ type: "success", message: res?.data?.message });
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const handleAdjustment = e => {
    const name = e.target.name;
    const value = e.target.value;
    const adjustFieldsObj = { ...adjustFields };
    adjustFieldsObj[name] = value;
    setAdjustFields(adjustFieldsObj);
  };

  const handleCreateAdjust = e => {
    e.preventDefault();
    toast.dismiss();

    setLoading(true);
    createTransactionAdjustment(adjustFields, id)
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          fetchAdjustmentTransations(adjustmentPageNo);
          if (adjustFields.effect == 0) {
            setSellerBalances({
              ...sellerBalances,
              pending_balance:
                parseFloat(adjustFields?.amount) +
                parseFloat(sellerBalances.pending_balance),
            });
          } else if (adjustFields.effect == 1) {
            setSellerBalances({
              ...sellerBalances,
              available_balance:
                parseFloat(adjustFields?.amount) +
                parseFloat(sellerBalances.available_balance),
            });
          }
          PrimaryToast({ type: "success", message: res?.data?.message });
          setAdjustFields({});
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const emailChanged = fields.store_email !== storeEmail;
    let data = {
      ...fields,
      seller_group: selectedOptions?.value,
      store_email: emailChanged ? fields?.store_email : undefined,
    };
    toast.dismiss();
    setLoading(true);
    updateSellerStore(data, id)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        if (emailChanged) {
          setEmail(fields?.store_email);
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message[0],
        });
      })
      .finally(res => setLoading(false));
  };

  const handleHyperwalletChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldsObj = { ...hyperwalletFields };
    fieldsObj[name] = value;
    if (name === "country") {
      if (value !== "US") {
        setIsProvinceText(true);
        fieldsObj["stateProvince"] = "";
      } else {
        setIsProvinceText(false);
      }
    }
    setHyperwalletFields(fieldsObj);
  };

  const handleHyperwalletValidation = () => {
    toast.dismiss();
    if (
      hyperwalletFields?.profileType?.toLowerCase() === "individual" &&
      !hyperwalletFields?.countryOfNationality
    ) {
      PrimaryToast({
        type: "error",
        message:
          "nationality field is required when business entity is individual",
      });
      return false;
    }
    return true;
  };

  const handleUpdateHyperwalletUser = e => {
    e.preventDefault();
    let updatedFields = {};

    Object.keys(hyperwalletFields)?.forEach(key => {
      if (hyperwalletFields[key] !== initialHyperwalletFields[key]) {
        if (hyperwalletFields[key] !== "") {
          if (key == "profileType") {
            updatedFields[key] = hyperwalletFields[key]?.toUpperCase();
          } else {
            updatedFields[key] = hyperwalletFields[key];
          }
        }
      }
    });

    let findCountries = countriesData?.find(
      item => item?.alpha2Code == hyperwalletFields?.country
    );
    updatedFields = {
      token: hyperwalletFields?.token,
      ...updatedFields,
      country: updatedFields?.country ? findCountries?.name : undefined,
    };

    if (handleHyperwalletValidation()) {
      toast.dismiss();
      setLoading(true);
      updateHyperwalletUser(updatedFields, id)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          setInitialHyperwalletData(hyperwalletFields);
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    }
  };

  const handleResetPassword = () => {
    setLoading(true);
    resetPasswordLink(id)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const handleDeleteAdjusment = () => {
    setLoading(true);
    deleteAdjusment(selectedId)
      .then(res => {
        setSelectedId("");
        setPopupTitle("");
        setShowDeleteModal(false);
        fetchAdjustmentTransations(adjustmentPageNo);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  const handleConfirm = () => {
    if (popupTitle == "Are you sure you want to delete this payout method?") {
      handleDeletePayout();
    } else if (popupTitle.includes("sync")) {
      handleMerchant();
    } else {
      handleDeleteAdjusment();
    }
  };

  const handleMerchant = () => {
    setLoading(true);
    syncMerchants(merchantSyncVal, id)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setMarchantValue(merchantSyncVal);
        setMerchantSyncVal("");
        setShowDeleteModal(false);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={popupTitle}
          onConfirm={handleConfirm}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
            setPopupTitle("");
            setMerchantSyncVal("");
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div>
        <div className="mb-3 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <TopHeading heading="Edit Store" />
            {kycStatus ? <KycVerificationBadge /> : null}
          </div>

          <div className="d-flex">
            <div className="me-2">
              <Button
                className="me-2"
                color="primary"
                type="button"
                disabled={kycStatus == 0 ? false : true}
                onClick={handleClickKyc}
              >
                Request KYC Information
              </Button>
            </div>
            {ordersExist && (
              <div className="me-2">
                <ExportButton
                  buttonText=" Download Transactions CSV"
                  isLoading={loading}
                  setIsLoading={setLoading}
                  fetchData={handleDownloadCsv}
                />
              </div>
            )}
            <Button onClick={() => history("/seller/stores")} className="me-2">
              Cancel
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={handleSubmit}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <div className="d-flex align-items-start">
              <SellerInformationForm
                handleSelectChange={handleSelectChange}
                handleChange={handleChange}
                selectedOptions={selectedOptions}
                fields={fields}
                handleResetPassword={handleResetPassword}
              />
              <div className="w-50 ms-4">
                <div className="d-flex align-items-center">
                  <h4 className="font-semi-bold mb-0 me-4 w-120">Merchant</h4>
                  <Button
                    color="primary"
                    disabled={loading}
                    className="px-2 py-1"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setMerchantSyncVal(3);
                      setPopupTitle(
                        "Are you sure you want to sync this seller on both google and bing"
                      );
                    }}
                  >
                    Sync In
                  </Button>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <p className="mb-0 me-4 w-120 fw-medium">
                    Not Sync{" "}
                    <span className="ps-4 font-semi-bold">
                      {marchantValue == 0 ? "Yes" : "No"}
                    </span>{" "}
                  </p>
                  {/* <Button
                    color="primary"
                    disabled={loading}
                    className="px-2 py-1"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setMerchantSyncVal(0)
                      setPopupTitle(
                        "Are you sure you want not sync this seller"
                      );
                    }}
                  >
                    Sync In
                  </Button> */}
                </div>
                <div className="d-flex align-items-center mt-2">
                  <p className="mb-0 me-4  w-120 fw-medium">
                    Google Sync{" "}
                    <span className="ps-2 font-semi-bold">
                      {marchantValue == 1 || marchantValue == 3 ? "Yes" : "No"}
                    </span>{" "}
                  </p>
                  <Button
                    disabled={loading}
                    color="primary"
                    className={`px-2 py-1 ${
                      marchantValue == 1 || marchantValue == 3
                        ? "d-none"
                        : "d-block"
                    }`}
                    onClick={() => {
                      setShowDeleteModal(true);
                      setMerchantSyncVal(1);
                      setPopupTitle(
                        "Are you sure you want to sync this seller on google "
                      );
                    }}
                  >
                    Sync In
                  </Button>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <p className="mb-0 me-4  w-120 fw-medium">
                    Bing Sync{" "}
                    <span className="ps-4 font-semi-bold">
                      {marchantValue == 2 || marchantValue == 3 ? "Yes" : "No"}
                    </span>{" "}
                  </p>
                  <Button
                    color="primary"
                    disabled={loading}
                    className={`px-2 py-1 ${
                      marchantValue == 2 || marchantValue == 3
                        ? "d-none"
                        : "d-block"
                    }`}
                    onClick={() => {
                      setShowDeleteModal(true);
                      setMerchantSyncVal(2);
                      setPopupTitle(
                        "Are you sure you want to sync this seller on Bing"
                      );
                    }}
                  >
                    Sync In
                  </Button>
                </div>{" "}
                <div className="d-flex align-items-center mt-2">
                  <p className="mb-0 me-4  w-120 fw-medium">
                    Both Sync{" "}
                    <span className="ps-4 font-semi-bold">
                      {marchantValue == 3 ? "Yes" : "No"}
                    </span>{" "}
                  </p>
                  <Button
                    disabled={loading}
                    color="primary"
                    className={`px-2 py-1 ${
                      marchantValue != 0 ? "d-none" : "d-block"
                    }`}
                    onClick={() => {
                      setShowDeleteModal(true);
                      setMerchantSyncVal(3);
                      setPopupTitle(
                        "Are you sure you want to sync this seller on both Google and Bing"
                      );
                    }}
                  >
                    Sync In
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <h4 className="font-semi-bold">Seller Verification</h4>
              </div>
              <div className="">
                <CommentSection
                  loading={loading}
                  id={location.id}
                  isLabel={true}
                />
              </div>
            </div>
            {Object.values(hyperwalletFields)[0] !== undefined && (
              <div>
                <HyperwalletUser
                  hyperwalletFields={hyperwalletFields}
                  handleHyperwalletChange={handleHyperwalletChange}
                  handleUpdateHyperwalletUser={handleUpdateHyperwalletUser}
                  isProvinceText={isProvinceText}
                  sellerId={id}
                  countries={countriesData}
                  states={statesData}
                  loading={loading}
                />
              </div>
            )}
            {payoutMethodsList?.data?.length > 0 && (
              <div className="mt-3">
                <PayoutMethodSection
                  payoutMethodsList={payoutMethodsList}
                  handleDeletePayout={handleDeletePayout}
                  setPayoutPage={setPayoutPage}
                  payoutPage={payoutPage}
                  setShowDeleteModal={setShowDeleteModal}
                  setSelectedId={setSelectedId}
                  setPopupTitle={setPopupTitle}
                />
              </div>
            )}
            {Object.values(hyperwalletFields)[0] !== undefined && (
              <StakeHolderTable
                stakeHoldersData={stakeHoldersData}
                sellerId={id}
                isHyperwalletUser={
                  Object.values(hyperwalletFields)[0] !== undefined
                }
                hasStakeholderEditPer={hasStakeholderEditPer}
              />
            )}
            {ordersExist && hasTransactionPer && (
              <TransactionAdjusmentComponent
                transactionAdjusmentData={transactionAdjusmentData}
                handleAdjustment={handleAdjustment}
                adjustFields={adjustFields}
                handleCreateAdjust={handleCreateAdjust}
                pageNo={adjustmentPageNo}
                pageCount={transPageCount}
                setPageNo={setAdjustMentPageNo}
                setLoading={setLoading}
                handleDeleteAdjusment={handleDeleteAdjusment}
                setSelectedId={setSelectedId}
                setPopupTitle={setPopupTitle}
                setShowDeleteModal={setShowDeleteModal}
                loading={loading}
              />
            )}
            {hasSellerBalanceAdjust && (
              <BalanceAdjusmentComponent
                id={id}
                loading={loading}
                setLoading={setLoading}
              />
            )}
            {sellerBalances?.wallet_exist && (
              <BalanceSection
                data={sellerBalances}
                setSellerBalances={setSellerBalances}
                sellerId={id}
                setLoading={setLoading}
                loading={loading}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default EditStore;
