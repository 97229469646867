import React, { useState } from "react";
import { Button } from "reactstrap";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import PrimaryToast from "../Toast/ToastMessage";
import { MerchantLabel } from "services/ProductsServices";

function MerchantLabelPopup({ handleClick, isOpen, loading, setLoading }) {
  const [fields, setFields] = useState({});
  const [selection, setSelection] = useState("");

  const handleChange = ({ target: { name, value } }) => {
    setFields(prev => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setFields({});
    setSelection("");
    handleClick();
  };

  const handleSave = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        is_admin: true,
        seller_id:
          selection === "seller" ? Number(fields.seller_id) || "" : undefined,
        product_id:
          selection === "product" ? Number(fields.product_id) || "" : undefined,
        label_0: fields.custom_label_0 || "",
        label_4: fields.custom_label_4 || "",
      };

      const response = await MerchantLabel(payload);

      if (response?.status >= 200 && response?.status < 300) {
        PrimaryToast({
          type: "success",
          message:
            response?.data?.message || "Merchant label updated successfully!",
        });
        handleClose();
      } else {
        throw new Error("Unexpected response from server.");
      }
    } catch (error) {
      PrimaryToast({
        type: "error",
        message:
          error?.response?.data?.message || "Failed to update merchant label.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryPopup
      title="Update Merchant Label"
      handleClick={handleClick}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
      isOpen={isOpen}
    >
      <div className="mb-3">
        <label className="form-label">Select ID Type</label>
        <div className="d-flex gap-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="selection"
              value="seller"
              id="seller"
              checked={selection === "seller"}
              onClick={() => setSelection("seller")}
            />
            <label className="form-check-label" htmlFor="seller">
              Seller ID
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="selection"
              value="product"
              id="product"
              checked={selection === "product"}
              onClick={() => setSelection("product")}
            />
            <label className="form-check-label" htmlFor="product">
              Product ID
            </label>
          </div>
        </div>
      </div>

      {selection && (
        <div className="mb-3">
          <PrimaryInput
            name={selection === "seller" ? "seller_id" : "product_id"}
            handleChange={handleChange}
            label={selection === "seller" ? "Seller ID" : "Product ID"}
            placeholder={`Enter ${
              selection === "seller" ? "Seller" : "Product"
            } ID`}
            value={
              fields[selection === "seller" ? "seller_id" : "product_id"] || ""
            }
          />
        </div>
      )}

      <div className="mb-3">
        <PrimaryInput
          name="custom_label_0"
          handleChange={handleChange}
          label="Custom Label 0"
          placeholder="Enter custom label 0"
          value={fields.custom_label_0 || ""}
        />
      </div>
      <div className="mb-3">
        <PrimaryInput
          name="custom_label_4"
          handleChange={handleChange}
          label="Custom Label 4"
          placeholder="Enter custom label 4"
          value={fields.custom_label_4 || ""}
        />
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          className="ms-2"
          disabled={loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </PrimaryPopup>
  );
}

export default MerchantLabelPopup;
