import React, { useState } from "react";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import { Button } from "reactstrap";
import PrimaryToast from "../Toast/ToastMessage";
import { sellerStoreSync } from "services/SellerStoreServices";
import ProgressPopup from "./ProgressPopup";

const SellerSyncPopup = ({ isOpen, handleClick }) => {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);

  const handlePopupClose = () => {
    setFields({});
    handleClick();
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await sellerStoreSync(fields);

      if (response.status === 200) {
        handlePopupClose();
      }
    } catch (err) {
      PrimaryToast({
        type: "error",
        message: (
          <div>
            {Array.isArray(err.response?.data?.message)
              ? err.response.data.message.map((msg, index) => (
                  <div key={index}>{msg}</div>
                ))
              : err.request?.response}
          </div>
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ProgressPopup label={"loading..."} />}
      <PrimaryPopup
        isOpen={isOpen}
        handleClick={handlePopupClose}
        title={"Seller Sync"}
      >
        <div className="border-bottom">
          <div className="mb-4">
            <PrimaryInput
              name="seller_id"
              placeholder={"Enter Seller Id"}
              handleChange={handleChange}
              value={fields?.seller_id}
              isRequired={true}
              label={"Seller ID"}
            />
          </div>
          <div className="mb-4">
            <PrimaryInput
              name="email"
              placeholder={"Enter Email"}
              handleChange={handleChange}
              value={fields?.email}
              isRequired={true}
              label={"Email"}
            />
          </div>
          <div className="mb-4">
            <PrimaryInput
              name="password"
              placeholder={"Enter Password"}
              handleChange={handleChange}
              value={fields?.password || ""}
              type={"password"}
              isRequired={true}
              label={"Password"}
            />
          </div>
          <div className="mb-4">
            <PrimaryInput
              name="shop_name"
              placeholder={"Enter Shop Name"}
              handleChange={handleChange}
              value={fields?.shop_name}
              isRequired={true}
              label={"Shop Name"}
            />
          </div>
          <div className="mb-4">
            <PrimaryInput
              name="slug"
              placeholder={"Enter Shop Slug"}
              handleChange={handleChange}
              value={fields?.slug}
              isRequired={true}
              label={"Slug"}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Button onClick={handlePopupClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            color="primary"
            className="ms-2"
            disabled={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </PrimaryPopup>
    </>
  );
};

export default SellerSyncPopup;
