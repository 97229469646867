import React, { useState } from "react";
import { Button } from "reactstrap";

import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import PrimaryToast from "../Toast/ToastMessage";
import { moveProduct } from "services/ProductsServices";

function MoveProductsPopupToSeller({ handleClick, isOpen, loading, setLoading }) {
  const [fields, setFields] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setFields(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setFields({});
    handleClick();
  };

  const handleSave = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        seller_id: Number(fields.seller_id), 
        product_ids: fields.product_ids
          ? fields.product_ids.split(",").map(id => Number(id.trim())) 
          : [],
      };


      const response = await moveProduct(payload);


      if (response?.status >= 200 && response?.status < 300) {
        // More flexible success check
        PrimaryToast({
          type: "success",
          message: response?.data?.message || "Products moved successfully!",
        });

        setTimeout(() => {
          setFields(prev => ({
            ...prev,
            product_ids: "",  
          }));
        }, 500); 
      } else {
        PrimaryToast({
          type: "error",
          message: "Unexpected response from server.",
        });
      }
    } catch (error) {
      PrimaryToast({
        type: "error",
        message: error?.response?.data?.message || "Failed to move products.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryPopup
      title={"Move Products"}
      handleClick={handleClick}
      isScrollable={false}
      className={"d-flex justify-content-center align-items-center h-100 w-100"}
      isOpen={isOpen}
    >
      <div className="mb-3">
        <PrimaryInput
          name="seller_id"
          handleChange={handleChange}
          label="Seller ID"
          placeholder="Enter seller id"
          value={fields?.seller_id || ""}
        />
      </div>
      <div className="mb-3">
        <label  className="form-label">Product IDs</label>
        <textarea
          name="product_ids"
          className="form-control"
          onChange={handleChange}
          value={fields?.product_ids || ""}
          placeholder="(e.g: 12, 13, 14...)"
        />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="button"
          className="ms-2"
          disabled={loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </PrimaryPopup>
  );
}

export default MoveProductsPopupToSeller;
